@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .header-item {
        @apply flex items-center
    }

    .header-item a {
        @apply mr-6 align-middle text-green-700 font-bold hover:text-green-400
    }

    .header-item-active a {
        @apply text-green-500 underline decoration-wavy decoration-green-700 underline-offset-4
    }
}