@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /* Character Build Guide Modal Window */
    .guide-position {
        @apply fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
    }

    .guide-size {
        @apply w-11/12 max-w-screen-2xl rounded-xl
    }

    .guide {
        @apply guide-position guide-size
    }

    .guide-anemo {
        @apply bg-gradient-to-br from-green-300 to-emerald-800
    }

    .guide-cryo {
        @apply bg-gradient-to-br from-sky-200 to-sky-400
    }

    .guide-dendro {
        @apply bg-gradient-to-br from-lime-200 to-lime-700
    }

    .guide-electro {
        @apply bg-gradient-to-br from-fuchsia-300 to-purple-800
    }

    .guide-geo {
        @apply bg-gradient-to-br from-yellow-200 to-amber-900
    }

    .guide-hydro {
        @apply bg-gradient-to-br from-cyan-400 to-blue-700
    }

    .guide-pyro {
        @apply bg-gradient-to-br from-orange-400 to-orange-800
    }

    /* Titlebar */
    .guide-titlebar {
        @apply px-1 w-full flex rounded-t-lg
    }

    .guide-titlebar-left {
        @apply flex
    }

    .guide-titlebar-image img {
        @apply w-16 h-16 object-cover object-top
    }

    .guide-titlebar-text {
        @apply pl-1 h-full flex flex-col justify-center
    }

    .guide-titlebar-text img {
        height: 19px;
        @apply self-center ml-0.5
    }

    .guide-titlebar-text h1 {
        @apply text-2xl font-bold text-black
    }

    .guide-titlebar-text span {
        @apply text-sm font-semibold bg-neutral-900 text-white rounded-full py-0.5 px-2 mr-1 inline-flex
    }

    .guide-titlebar-right {
        @apply ml-auto float-right my-auto pr-2
    }

    .guide-titlebar-right button {
        @apply px-1 py-1 bg-neutral-900 hover:opacity-60 text-white hover:text-black cursor-pointer rounded-full
    }

    .guide-titlebar-right button img {
        @apply w-5 h-5 fill-white
    }

    /* Content  */
    .guide-content {
        @apply text-white py-1 px-1
    }

    .guide-content-tabs {
        @apply text-white font-semibold
    }

    .guide-content-tabs div {
        @apply bg-neutral-900 bg-opacity-70 px-6 py-1.5 mr-2 rounded-t-xl select-none
    }

    .guide-content-tabs div:hover{
        @apply bg-opacity-90 cursor-pointer
    }

    .guide-content-tabs .active-tab {
        @apply bg-opacity-100 hover:bg-opacity-100
    }

    .guide-content-build {
        @apply rounded-tr-lg rounded-b-lg border-neutral-900 p-4 bg-neutral-900
    }


}
