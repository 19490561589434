@font-face {
  font-family: "Genshin Font";
  src: url("../assets/zh-cn.ttf");
}

.character {
  font-family: "Genshin Font";
  font-size: 15px;
  line-height: 1.5rem;
  width: 112px;
  height: 136px;
  background-size: contain;
  background-repeat: no-repeat;
}

.character img {
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
  border-bottom-right-radius: 20%;
}

.rarity-5 {
  background-image: url("../assets/Rarity_5_background.webp");
}

.rarity-5a {
  background-image: url("../assets/Rarity_5a_background.webp");
}

.rarity-4 {
  background-image: url("../assets/Rarity_4_background.webp");
}

.vision {
  width: 24px;
  position: absolute;
}